import React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { WhyPolymerizeCard } from './why-polymerize-card'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { TranslationKey } from 'types/translation';

type P = {
    heading: TranslationKey
    subheading: TranslationKey
    data: {
        label: TranslationKey
        heading: TranslationKey
        subheading: TranslationKey
        image: string
    }[]
}

export type WhyPolymerizeProps = P

export const WhyPolymerize = ({ data, heading, subheading }: P) => {
    const { t } = useTranslation()

    return (
        <section
            id="why-polymerize"
            className="flex flex-col gap-16 sm:gap-20"
        >
            <div className='flex flex-col gap-8 items-center'>
                <div className='flex flex-col gap-8 items-center'>
                    <StaticImage
                        alt={t('common.words.why-polymerize')}
                        src="../../../../images/icon_why_polymerize.png"
                        className="w-20 sm:w-40"
                    />
                    <span className="tracking-wider text-gray-500 uppercase">
                        {t('common.words.why-polymerize')}
                    </span>
                </div>

                <div className='flex flex-col gap-4 text-center'>
                    <p className="text-4xl sm:text-5xl">
                        <Trans i18nKey={`${heading}`} />
                    </p>
                    <p className="sm:text-lg md:text-xl">
                        <Trans i18nKey={`${subheading}`} />
                    </p>
                </div>
            </div>

            <div className='flex flex-col gap-20 sm:gap-32'>
                {data.map((card, i) => {
                    return (
                        <WhyPolymerizeCard
                            key={card.label}
                            type={i % 2 === 0 ? 'default' : 'reverse'}
                            {...card}
                        />
                    )
                })}
            </div>
        </section>
    )
}
