import React from "react";
import { PrimaryBtn, Container } from 'components/library'
import RapidDevelopment from 'images/illus_maximise_prod_dev.png'
import OperationalEfficiency from 'images/illus_scale_efficiency.png'
import ScalableMachineLearning from 'images/illus_eliminate_silos.png'
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { MiniNavbar } from "components/common/mini-navbar";
import { LearnMoreCta } from "components/common/learn-more-cta";
import { ResultsBlock } from "components/pages/solutions/results-block";
import { TechScoutsHero } from "components/pages/solutions/tech-scouts/tech-scouts-hero";
import { TechScoutsAcceleratingResearch } from "components/pages/solutions/tech-scouts/accelerating-research";
import { TechScoutsDrivingCollaboration } from "components/pages/solutions/tech-scouts/driving-collaboration";
import { WhyPolymerize, WhyPolymerizeProps } from "components/pages/solutions/why-polymerize/why-polymerize";
import { UseCases } from "components/pages/solutions/use-cases";

const whyPolymerizeData: WhyPolymerizeProps['data'] = [
  {
    label: 'technology-scounts-page.why-polymerize.reasons.1.label',
    heading: 'technology-scounts-page.why-polymerize.reasons.1.heading',
    subheading: 'technology-scounts-page.why-polymerize.reasons.1.subheading',
    image: RapidDevelopment
  },
  {
    label: 'technology-scounts-page.why-polymerize.reasons.2.label',
    heading: 'technology-scounts-page.why-polymerize.reasons.2.heading',
    subheading: 'technology-scounts-page.why-polymerize.reasons.2.subheading',
    image: OperationalEfficiency
  },
  {
    label: 'technology-scounts-page.why-polymerize.reasons.3.label',
    heading: 'technology-scounts-page.why-polymerize.reasons.3.heading',
    subheading: 'technology-scounts-page.why-polymerize.reasons.3.subheading',
    image: ScalableMachineLearning
  },
]

const ProductPage = () => {
  const { t } = useTranslation()

  return (
    <main>
      <Container className="flex flex-col gap-36 py-16">
        <div className='flex flex-col w-full gap-16'>
          <MiniNavbar
            className='hidden sm:flex'
            title='common.words.technology-scouts'
            links={[
              { label: 'common.words.use-cases', path: '#use-cases' },
              { label: 'common.words.why-polymerize', path: '#why-polymerize' },
              { label: 'common.words.customers', path: '#customers' },
            ]}
          />

          <TechScoutsHero />
        </div>

        <UseCases
          title="technology-scounts-page.use-cases.title"
          subtitle="technology-scounts-page.use-cases.subtitle"
        />

        <TechScoutsAcceleratingResearch />

        <section className='sm:py-12 w-full flex justify-center bg-molecule-bg'>
          <div className='flex flex-col gap-8'>
            <h2 className="text-5xl max-w-screen-md tracking-tight text-gray-900 sm:text-6xl">
              {t('common.messages.drive-rnd-w-ai')}
            </h2>

            <div className='flex gap-3'>
              <PrimaryBtn
                className='md:text-lg max-w-max'
                onClick={() => window.requestADemo()}
              >
                {t('common.cta.demo')}
              </PrimaryBtn>
              <LearnMoreCta />
            </div>
          </div>
        </section>

        <TechScoutsDrivingCollaboration />

        <WhyPolymerize
          heading='technology-scounts-page.why-polymerize.heading'
          subheading='technology-scounts-page.why-polymerize.subheading'
          data={whyPolymerizeData}
        />
      </Container>

      <ResultsBlock />
    </main >
  );
};

export default ProductPage;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 