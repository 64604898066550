import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ArrowRight } from "react-feather"
import { Trans } from "gatsby-plugin-react-i18next"
import { TranslationKey } from "types/translation"

type P = {
	title: TranslationKey
	subtitle: TranslationKey
}

export const UseCases = ({ subtitle, title }: P) => {
	return (
		<section
			id="use-cases"
			className="text-center flex flex-col gap-8 items-center"
		>
			<StaticImage
				alt="illustration"
				src="../../../images/icon_use_cases.png"
				className="w-20 sm:w-40"
			/>
			<span className="tracking-wider text-gray-500">
				<Trans i18nKey="common.words.use-cases" />
			</span>

			<div className="flex flex-col gap-2">
				<h2 className="text-4xl tracking-tight text-gray-900 sm:text-5xl">
					<Trans i18nKey={`${title}`} />
				</h2>
				<p className="text-base sm:text-lg md:text-xl">
					<Trans
						i18nKey={`${subtitle}`}
						components={{
							anchor: <a className="anchor" />,
						}}
					/>
				</p>
			</div>

			<span
				onClick={() => (window as any).requestADemo()}
				className="text-theme-blue flex items-center gap-2 justify-center cursor-pointer"
			>
				<span>
					<Trans i18nKey="common.cta.demo" />
				</span>
				<ArrowRight size={20} />
			</span>
		</section>
	)
}
