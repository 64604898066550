import React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { TranslationKey } from 'types/translation';

type P = {
    title: TranslationKey
    desc: TranslationKey
    image: string
};

export type CardProps = P

export const Card = ({ desc, image, title }: P) => {
    const { t } = useTranslation()

    return (
        <div className='flex flex-col gap-2 items-center sm:items-start shadow-md p-4 rounded-lg'>
            <img
                src={image}
                alt={t(title) as string}
                className="w-16"
            />
            <p className="text-2xl">
                <Trans i18nKey={`${title}`} />
            </p>
            <p className="text-base">
                <Trans i18nKey={`${desc}`} />
            </p>
        </div>
    )
};
