import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Card, CardProps } from "./accelerating-research-card"
import PredictFormulations from "images/illus_features_predict_formulation.png"
import ScalableML from "images/illus_features_scale_ML.png"
import GenerateCustomCharts from "images/illus_features_generate_custom.png"
import ManageData from "images/illus_manage_data.png"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

const features: CardProps[] = [
	{
		title: "technology-scounts-page.accelerating-research.features.1.title",
		desc: "technology-scounts-page.accelerating-research.features.1.desc",
		image: PredictFormulations,
	},
	{
		title: "technology-scounts-page.accelerating-research.features.2.title",
		desc: "technology-scounts-page.accelerating-research.features.2.desc",
		image: ScalableML,
	},
	{
		title: "technology-scounts-page.accelerating-research.features.3.title",
		desc: "technology-scounts-page.accelerating-research.features.3.desc",
		image: GenerateCustomCharts,
	},
	{
		title: "technology-scounts-page.accelerating-research.features.4.title",
		desc: "technology-scounts-page.accelerating-research.features.4.desc",
		image: ManageData,
	},
]

export const TechScoutsAcceleratingResearch = () => {
	const { t } = useTranslation()

	return (
		<section className="text-center flex flex-col gap-12">
			<div className="flex justify-around flex-col-reverse gap-6 items-center sm:gap-28 sm:flex-row sm:text-left">
				<div className="flex flex-col gap-4">
					<h2 className="text-4xl tracking-tight sm:text-5xl">
						{t("technology-scounts-page.accelerating-research.heading")}
					</h2>

					<p className="text-base text-gray-900 sm:text-lg md:text-xl">
						<Trans
							i18nKey="technology-scounts-page.accelerating-research.desc"
							components={{
								anchor: <a className="anchor" />,
							}}
						/>
					</p>
				</div>

				<StaticImage
					alt="illustration"
					className="w-20 sm:w-40 rounded-md shrink-0"
					src="../../../../images/illus_icon_for_researchers.png"
				/>
			</div>

			<div className="grid sm:grid-cols-4 w-full sm:text-left gap-12">
				{features.map((feature) => (
					<Card key={feature.title} {...feature} />
				))}
			</div>
		</section>
	)
}
