import { PrimaryBtn } from 'components/library';
import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

export const TechScoutsHero = () => {
    const { t } = useTranslation()

    return (
        <section className="flex flex-col sm:justify-between sm:flex-row items-center lg:text-left gap-8">
            <div className='sm:w-[60%] flex flex-col gap-10'>
                <h1 className="text-4xl tracking-wider font-light sm:text-5xl md:text-7xl bg-gradient-to-r from-theme-blue to-theme-orange bg-clip-text text-transparent">
                    {t('technology-scounts-page.hero.heading')}
                </h1>
                <p className="text-base sm:text-lg sm:max-w-xl md:text-2xl">
                    {t('technology-scounts-page.hero.description')}
                </p>
                <PrimaryBtn
                    className='md:text-lg max-w-max'
                    onClick={() => window.requestADemo()}
                >
                    {t('common.cta.demo')}
                </PrimaryBtn>
            </div>

            <StaticImage
                alt="illustration"
                src="../../../../images/illus_solutions_proj_management.png"
                className="mt-16 sm:mt-0 sm:w-1/2 object-contain"
            />
        </section>
    )
};
