import React from "react"
import { ComponentPropsWithoutRef } from 'react'
import { TranslationKey } from 'types/translation'
import { mergeClasses } from 'utils'
import { Trans } from 'gatsby-plugin-react-i18next'

type P = ComponentPropsWithoutRef<'nav'> & {
    title: TranslationKey
    links: {
        label: TranslationKey
        path: string
    }[]
}

export const MiniNavbar = ({ title, links, className = '', ...props }: P) => {
    return (
        <nav
            className={
                mergeClasses({ classes: ['text-base flex justify-between gap-8', className] })
            }
            {...props}
        >
            <span className='uppercase'>
                <Trans i18nKey={`${title}`} />
            </span>

            <div className="flex gap-12">
                {links.map(link => {
                    return (
                        <a
                            key={link.label}
                            href={link.path}
                            className="hover:text-theme-blue-hover"
                        >
                            <Trans i18nKey={`${link.label}`} />
                        </a>
                    )
                })}
            </div>
        </nav>
    )
}
