import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Card, CardProps } from "./driving-collaboration-card"
import ProjectManagement from "images/illus_features_project_mgmt.png"
import GranularTeam from "images/illus_features_user_permissions.png"
import ManageData from "images/illus_manage_data.png"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

const features: CardProps[] = [
	{
		title: "technology-scounts-page.driving-collaboration.features.1.title",
		desc: "technology-scounts-page.driving-collaboration.features.1.desc",
		image: ProjectManagement,
	},
	{
		title: "technology-scounts-page.driving-collaboration.features.2.title",
		desc: "technology-scounts-page.driving-collaboration.features.2.desc",
		image: GranularTeam,
	},
	{
		title: "technology-scounts-page.driving-collaboration.features.3.title",
		desc: "technology-scounts-page.driving-collaboration.features.3.desc",
		image: ManageData,
	},
]

export const TechScoutsDrivingCollaboration = () => {
	const { t } = useTranslation()

	return (
		<section className="text-center flex flex-col gap-12">
			<div className="flex justify-between flex-col-reverse gap-6 sm:items-center sm:gap-28 sm:flex-row text-left">
				<div className="flex flex-col gap-4">
					<h2 className="text-4xl tracking-tight sm:text-5xl">
						{t("technology-scounts-page.driving-collaboration.heading")}
					</h2>
					<p className="text-base text-gray-900 sm:text-lg md:text-xl max-w-2xl">
						<Trans
							i18nKey="technology-scounts-page.driving-collaboration.desc"
							components={{
								anchor: <a className="anchor" />,
							}}
						/>
					</p>
				</div>

				<StaticImage
					alt="illustration"
					className="w-20 sm:w-40 rounded-md shrink-0"
					src="../../../../images/illus_icon_for_operations.png"
				/>
			</div>

			<div className="grid sm:grid-cols-4 w-full sm:text-left gap-12">
				{features.map((feature) => (
					<Card key={feature.title} {...feature} />
				))}
			</div>
		</section>
	)
}
