import React from "react"
import { ArrowRight } from 'react-feather'
import { TranslationKey } from 'types/translation'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

type P = {
    label: TranslationKey
    heading: TranslationKey
    subheading: TranslationKey
    image: string
    type: 'default' | 'reverse'
}

export const WhyPolymerizeCard = ({ heading, label, type, subheading, image }: P) => {
    const { t } = useTranslation()

    return (
        <div className="grid sm:grid-cols-2 items-center gap-16">
            <img
                className={`rounded-3xl object-contain bg-gradient-box bg-cover p-6 ${type === 'reverse' ? "" : "sm:hidden"}`}
                alt={t(heading) as string}
                src={image}
            />

            <div className='flex flex-col gap-8'>
                <span className="tracking-wider bg-gradient-to-r from-yellow-500 to-blue-500 bg-clip-text text-transparent uppercase">
                    <Trans i18nKey={`${label}`} />
                </span>

                <h3 className="text-4xl tracking-tight sm:text-5xl">
                    <Trans i18nKey={`${heading}`} />
                </h3>

                <p className="text-base text-gray-900 sm:text-lg md:text-xl">
                    <Trans i18nKey={`${subheading}`} />
                </p>

                <span
                    onClick={() => (window as any).requestADemo()}
                    className="text-theme-blue flex gap-2 items-center cursor-pointer"
                >
                    <span>
                        {t('common.cta.demo')}
                    </span>
                    <ArrowRight size={20} />
                </span>
            </div>

            <img
                className={`rounded-3xl object-scale-down bg-gradient-box bg-cover p-6 ${type === 'default' ? "hidden sm:inline-flex" : "hidden"}`}
                alt={t(heading) as string}
                src={image}
            />
        </div>
    )
}
