import React from "react";
import { TranslationKey } from 'types/translation';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

type P = {
    title: TranslationKey
    desc: TranslationKey
    image: string
}

export type CardProps = P

export const Card = ({ desc, image, title }: P) => {
    const { t } = useTranslation()

    return (
        <div className='flex flex-col gap-2 items-center sm:items-start'>
            <img
                className="w-16"
                src={image}
                alt={t(title) as string}
            />

            <p className="text-2xl">
                <Trans i18nKey={`${title}`} />
            </p>
            <p>
                <Trans i18nKey={`${desc}`} />
            </p>
        </div>
    )
};
