import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, PrimaryAlternativeBtn } from "components/library"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

export const ResultsBlock = () => {
	const { t } = useTranslation()

	return (
		<section
			id="customers"
			className="text-center bg-no-repeat bg-cta-bg bg-cover w-full py-24 text-white"
		>
			<Container className="flex flex-col gap-20">
				<div className="flex flex-col gap-12 items-center">
					<div className="flex flex-col gap-4 items-center">
						<StaticImage
							alt="illustration"
							src="../../../images/icon_customers.png"
							className="w-20 sm:w-40"
						/>
						<span className="tracking-wider uppercase">
							{t("common.words.customers")}
						</span>
					</div>

					<p className="text-3xl tracking-tight">
						{t("solutions-results-block.header.title")}
					</p>

					<div className="flex text-left sm:border-x-[1px] items-center border-white px-10 gap-10">
						<div className="flex flex-col gap-8 max-w-[200px]">
							<p className="font-semibold">{t("common.words.productivity")}</p>
							<p className="text-3xl sm:text-6xl">50%</p>
							<p className="text-lg sm:text-2xl">
								{t("solutions-results-block.header.features.1.content")}
							</p>
						</div>

						<span className="h-full flex min-h-[280px] grow w-[1px] shrink-0 bg-white"></span>

						<div className="flex flex-col gap-8 max-w-[200px]">
							<p className="font-semibold">{t("common.words.accuracy")}</p>
							<p className="text-3xl sm:text-6xl">80%</p>
							<p className="text-lg sm:text-2xl">
								{t("solutions-results-block.header.features.2.content")}
							</p>
						</div>
					</div>
				</div>

				<div className="flex flex-col gap-20">
					<h2 className="text-left text-4xl tracking-tight sm:text-5xl">
						{t("solutions-results-block.body.heading")}
					</h2>

					<div className="grid sm:grid-cols-2 text-left gap-12">
						<div className="flex flex-col gap-3">
							<p className="text-2xl">
								{t("solutions-results-block.body.features.1.title")}
							</p>
							<p>{t("solutions-results-block.body.features.1.desc")}</p>
						</div>
						<div className="flex flex-col gap-3">
							<p className="text-2xl">
								{t("solutions-results-block.body.features.2.title")}
							</p>
							<p>
								<Trans
									i18nKey="solutions-results-block.body.features.2.desc"
									components={{
										anchor: <a className="font-semibold" />,
									}}
								/>
							</p>
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<h3 className="text-4xl text-left tracking-tight font-extrabold sm:text-5xl">
							{t("common.messages.get-started-today")}
						</h3>

						<PrimaryAlternativeBtn
							className="md:text-lg max-w-max"
							onClick={() => window.requestADemo()}
						>
							{t("common.cta.demo")}
						</PrimaryAlternativeBtn>
					</div>
				</div>
			</Container>
		</section>
	)
}
